<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-list-item dense>
          <v-list-item-avatar>
            <v-icon :color="iconColorClass">{{ iconNote }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title :class="titleClass">
              {{ title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid>
          <v-list three-line>
            <template v-for="(note, index) in notes">
              <!-- note -->
              <v-list-item :key="index">
                <v-list-item-avatar>
                  <v-icon :color="iconColorClass">{{ iconNote }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="noteTitle(note)"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="subtitle(note)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- note divider -->
              <v-divider
                v-if="visibleDivider(index)"
                :key="`divider_${index}`"
                :inset="true"
              ></v-divider>
            </template>
          </v-list>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// design
import { iconNote } from "@/design/icon/iconConst";
import { displayTypes, Text, fontEmphasis } from "@/design/text/Text";

// filters
import { toLocalDateTimeString } from "@/filters/dateFilter";

// model
import { noteColorClass } from "@/model/workflow/task/taskModel";

// services
import { titleClass } from "@/services/text/textService";

export default {
  name: "TaskNotes",
  data() {
    return {
      iconNote: iconNote
    };
  },
  props: {
    notes: undefined
  },
  computed: {
    computedNotes() {
      return this.notes ?? [];
    },
    iconColorClass() {
      return noteColorClass();
    },
    titleTextClass() {
      return new Text(undefined, displayTypes.title, fontEmphasis.regular);
    },
    noteCount() {
      return this.notes?.length ?? 0;
    },
    title() {
      return this.noteCount <= 1
        ? `(${this.noteCount}) Note`
        : `(${this.noteCount}) Notes`;
    },
    titleClass() {
      return titleClass();
    }
  },
  methods: {
    /**
     * noteTitle
     * @param {{id: number, created: string, taskId: number, text: string, userId: number, userName: string}} note
     * @return {string|string}
     */
    noteTitle(note) {
      return note
        ? `By ${note.userName} on ${toLocalDateTimeString(note.created)}`
        : "";
    },

    /**
     * subtitle
     * @param {{id: number, created: string, taskId: number, text: string, userId: number, userName: string}} note
     * @return {String|string}
     */
    subtitle(note) {
      return note?.text ?? "";
    },

    /**
     * visible Divider
     * @param {Number|number} noteIndex note Index
     * @return {boolean} true when visible Divider
     */
    visibleDivider(noteIndex) {
      return noteIndex < (this.computedNotes?.length ?? 0) - 1;
    }
  }
};
</script>
